.containter-404 .content-404[data-v-15077f65] {
  height: 260px;
  width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -130px 0 0 -300px;
}
.containter-404 .content-404 img[data-v-15077f65] {
    width: 80%;
    height: 80%;
}
.containter-404 .content-404 p[data-v-15077f65] {
    margin-top: 20px;
}
